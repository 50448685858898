import * as React from "react";

interface AnchorListProp {
  items: Array<string>;
}

interface AnchorHeadingProp {
  heading: string;
}

// markup
const AnchorList: React.FC<AnchorListProp> = (props) => {
  return (
    <ul
      className="c-anchors__list uk-list uk-list-large uk-text-left@s uk-text-center"
      data-uk-scrollspy-nav="closest: li; scroll: true; offset: 100"
    >
      {props.items.map((item: any, index: number) => {
        return (
          <li key={`content_item_${index}`}>
            <a
              href={`#${item.replace(/[^0-9a-zA-Z]/g, "-")}`}
              className="uk-link-muted"
            >
              {item}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

// markup
const AnchorHeading: React.FC<AnchorHeadingProp> = (props) => {
  return (
    <h2
      id={props.heading.replace(/[^0-9a-zA-Z]/g, "-")}
      className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl uk-margin-small-bottom"
    >
      {props.heading}
    </h2>
  );
};

export { AnchorHeading, AnchorList };
