import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import BannerBasic from "../../components/Content/Banner/Basic";
import LeadIn from "../../components/Content/LeadIn/Index";
import {
  AnchorHeading,
  AnchorList,
} from "../../components/Content/Anchor/Index";
import {
  Listing,
  ListingItem,
} from "../../components/Content/TextIcon/Listing";

// assets
import iconSearch from "../../assets/icons/icon-search.svg";

// data
import faqs from "../../pages/static-pages/content/downloads.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Downloads"
        description="Mi neque magna hac mollis nostra montes leo felis, magnis phasellus libero laoreet facilisi enim malesuada in, posuere nec lacinia dui aliquet duis sem."
      />
      <BannerBasic title="Downloads" />
      <section className="uk-section">
        <div className="uk-container">
          <div data-uk-grid="true">
            <div className="c-anchors uk-width-1-1 uk-width-1-4@s c-noprint">
              <h2 className="uk-hidden@s uk-h5 uk-text-center">
                Skip to Section
              </h2>
              <div
                className="uk-position-z-index"
                id="table-contents"
                data-uk-sticky="offset: 50; bottom: true; media: @s"
              >
                <AnchorList
                  items={[
                    "Conex Bänninger",
                    ">B< Press",
                    ">B< Press XL",
                    ">B< MaxiPro",
                    ">B< Press Stainless Steel",
                    "Certificates",
                  ]}
                />
              </div>
              <hr className="uk-hidden@s" />
            </div>
            <div className="uk-width-1-1 uk-width-3-4@s">
              {faqs.section.map((item: any, index: number) => (
                <React.Fragment key={`question_${index}`}>
                  <AnchorHeading heading={item.heading} />
                  <Listing>
                    {item.downloads.map((item: any, index: number) => {
                      const icon = item.iconUrl
                        ? item.iconUrl
                        : "/icons/icon-document.svg";
                      return (
                        <React.Fragment key={`download_${index}`}>
                          <ListingItem
                            heading={item.heading}
                            content={item.content}
                            url={item.url}
                            iconUrl={icon}
                            iconTitle=""
                            iconSize="default"
                            buttonTarget="_blank"
                            buttonRel="noopener noreferrer"
                          />
                        </React.Fragment>
                      );
                    })}
                  </Listing>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
    </Layout>
  );
};

export default IndexPage;
