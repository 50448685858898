import * as React from "react";

// components
import LargeIcons from "../Icons/Large";

interface Prop {
  heading: string;
  content: string;
  url: string;
  iconSize?: "default" | "large" | undefined;
  iconUrl: string;
  iconTitle: string;
  buttonTarget?: "_self" | "_blank";
  buttonRel?: string | undefined;
}

// markup
const Listing: React.FC = ({ children }) => {
  return (
    <>
      <ul className="uk-list c-listing-icon uk-margin-medium-top uk-margin-xlarge-bottom ">
        {children}
      </ul>
      <hr />
    </>
  );
};

// markup
const ListingItem: React.FC<Prop> = (props) => {
  return (
    <li>
      <a
        className="uk-flex uk-flex-middle uk-link-reset"
        href={props.url}
        target={props.buttonTarget}
        rel={props.buttonRel}
      >
        <div>
          <LargeIcons
            iconUrl={props.iconUrl}
            iconTitle={props.iconTitle}
            iconSize={props.iconSize}
          />
        </div>
        <div className="c-listing-icon__content uk-margin-left">
          <strong className="uk-display-block uk-margin-small-bottom">
            {props.heading}
          </strong>
          <span className="uk-display-block">{props.content}</span>
        </div>
      </a>
    </li>
  );
};

export { Listing, ListingItem };
